// src/pages/DeviceCheck.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Button, TextField, Typography, AppBar, Toolbar } from '@mui/material';
import { styled } from '@mui/system';
import logoImg from '../assets/4GFlota_logo.svg';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import config from '../config/config';
import axios from 'axios';
import Alert from '../components/Alert';
import { useDeviceContext } from '../context/DeviceContext';
import CheckInternetConnection from '../utils/InternetUtils';
import { useLoading } from '../context/LoadingContext';
import { useAlert } from '../context/AlertContext'; // Importa useAlert

const RoundedButton = styled(Button)(({ theme }) => ({
  margin: 'auto',
  borderRadius: '50%', // Hace que el botón sea completamente redondo
  width: '130px',
  height: '130px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(1), // Ajusta el espacio interno del botón según sea necesario
  marginTop: 30
}));

const StyledAppBar = styled(AppBar)({
  backgroundColor: (theme) => theme.palette.primary.main,
  height: '70px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: 'none',
});

const StyledLogo = styled('img')({
  width: '40%',
  height: 'auto',
});

const StyledFormContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(3), // Ajusta según tu necesidad, usa theme.spacing() correctamente
  display: 'flex',
  flexDirection: 'column',

  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,

  padding: theme.spacing(4),
}));

function DeviceCheck() {
  const { serialNumber, updateSerialNumber, updateResponseData, getFormattedDate, updateTimestamp, formattedDate } = useDeviceContext();
  const { showAlert } = useAlert(); // Usa showAlert del contexto de alerta
  const navigate = useNavigate();
  const { showLoading, hideLoading } = useLoading();

  // Función para obtener el timestamp actual
  const getCurrentTimestamp = () => {
    // Crea un objeto Date para la fecha actual
    const currentDate = new Date();
    // Obtiene el timestamp en milisegundos
    const timestamp = currentDate.getTime();

    // Retorna el timestamp
    return timestamp;
  };

  const handleVerifyClick = async () => {
    if (!serialNumber || serialNumber === '0') {
      showAlert('error', 'Error', 'El número de serie no puede estar vacío o ser igual a 0.');
      return;
    }

    if (!navigator.geolocation) {
      showAlert('error', 'Error', 'La geolocalización no es soportada por tu navegador.');
      return;
    }

    const online = await CheckInternetConnection();
    if (!online) {
      navigate('/connection-error', {
        state: {
          serialNumber,
          errorMessage: 'Error de conexión.',
          errorDescription: 'Compruebe que su dispositivo esté conectado a Internet.',
          textList1: 'Disponer de cobertura móvil',
          textList2: 'Tener activado el GPS del móvil',
          textList3: ' Disponer de conexión de datos',
          errorType: ""
        }
      });
    } else {
      showLoading();
      setTimeout(() => {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;
            let isFirstCall = true;
            const timestamp = getCurrentTimestamp();
            try {
              const params = new URLSearchParams({
                serialNumber,
                latitude,
                longitude,
                timestamp: timestamp,
                isFirstCall
              }).toString();

              const response = await axios.get(`${config.apiGateway.URL}Devices/Verify?${params}`);
              switch (response.status) {
                case 200:
                  updateResponseData(response.data);
                  getFormattedDate(formattedDate)
                  updateTimestamp(timestamp);
                  navigate('/device-verification', {
                    state: { data: response.data }
                  });
                  break;
                default:
                  showAlert('error', 'Error', 'Error en la verificación del dispositivo. Inténtalo de nuevo.');
                  break;
              }
            } catch (error) {
              getFormattedDate(formattedDate);
              if (error.response) {
                const { status } = error.response;
                switch (status) {
                  case 410:
                    navigate('/connection-error', {
                      state: {
                        serialNumber: serialNumber,
                        date: formattedDate,
                        errorMessage: 'Nº Serie incorrecto',
                        errorDescription: 'El nº de serie introducido es incorrecto',
                        textButton: 'Reintentar',
                        errorType: ""
                      }
                    });
                    break;
                  case 411:
                    navigate('/connection-error', {
                      state: {
                        serialNumber: serialNumber,
                        date: formattedDate,
                        errorMessage: 'Fuera de rango',
                        errorDescription: 'El dispositivo móvil está fuera de rango con el vehículo',
                        textList1: 'Asegurese que el dispositivo está en un radio de 20m en función de su localización.',
                        textButton: 'Reintentar',
                        errorType: ""
                      }
                    });
                    break;
                  case 412:
                    navigate('/connection-error', {
                      state: {
                        serialNumber: serialNumber,
                        date: formattedDate,
                        errorMessage: 'Tiempo de Transmisión mayor de 5 minutos.',
                        errorDescription: 'Asegúrese que el dispositivo está transmitiendo.',
                        textList1: "Puede acelerar la transmisión encendiendo y apagando el motor.",
                        textButton: 'Reintentar',
                        errorType: ""
                      }
                    });
                    break;
                  case 413:
                    navigate('/connection-error', {
                      state: {
                        serialNumber: serialNumber,
                        date: formattedDate,
                        errorMessage: 'Sin GPS en el vehículo.',
                        errorDescription: 'El dispositivo no dispone de señal GPS.',
                        textList1: "Asegúrese que está en un entorno abierto donde pueda obtener señal.",
                        textButton: 'Reintentar',
                        errorType: ""
                      }
                    });
                    break;
                  default:
                    showAlert('error', 'Error', 'Error en la verificación del dispositivo. Inténtalo de nuevo.');
                    break;
                }
              } else {
                showAlert('error', 'Error', 'Hubo un error en la verificación del dispositivo. Inténtalo de nuevo.');
              }
            } finally {
              hideLoading(); // Ocultar loading
            }
          },
          (error) => {
            hideLoading();
            navigate('/connection-error', {
              state: {
                serialNumber: serialNumber,
                date: formattedDate,
                errorMessage: 'Sin GPS en el móvil.',
                errorDescription: 'Debe tener activado el GPS del móvil.',
                textButton: 'Reintentar',
                errorType: ""
              }
            });
          }
        );
      }, 1000);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleVerifyClick();
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <StyledAppBar position="static">
        <Toolbar>
          <Box sx={{ textAlign: 'center', mt: -6 }}>
            <StyledLogo src={logoImg} alt="4GFlota" />
          </Box>
        </Toolbar>
      </StyledAppBar>
      <StyledFormContainer component="main" maxWidth="xs">
        <Typography variant="h5" component="div" sx={{ flexGrow: 1, color: (theme) => theme.palette.primary.main }}>
          Comprobar dispositivo
        </Typography>
        <Typography component="div" variant="h6" gutterBottom>
          Introduzca el número de serie del dispositivo GPS
        </Typography>
        <Box noValidate sx={{ width: '100%', mt: 1 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="deviceNumber"
            label="Número de serie"
            name="deviceNumber"
            inputProps={{
              maxLength: 10,
            }}
            autoFocus
            type='tel'
            value={serialNumber}
            onChange={(e) => {
              if (e.target.value.length <= 10) {
                updateSerialNumber(e.target.value);
              }
            }}
            onKeyPress={handleKeyPress}
          />
          <RoundedButton
            variant="contained"
            color="primary"
            onClick={handleVerifyClick} // Maneja el clic para navegar
          >
            <CheckCircleOutlineIcon style={{ fontSize: 105 }} />
          </RoundedButton>
          <Typography style={{ color: '#5f5f5f', marginTop: 24, fontSize: 15 }} variant="body2" component="div" align="center">
            Verificar
          </Typography>
        </Box>
      </StyledFormContainer>
      <Alert /> {/* Renderiza el componente Alert para mostrar las alertas */}
    </Box>
  );
}

export default DeviceCheck;
