import React, { useEffect, useCallback } from 'react';
import { AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo4G from '../assets/ico_4gflota.svg';
import { useAlert } from '../context/AlertContext';
import { useLoading } from '../context/LoadingContext';
import { useDeviceContext } from '../context/DeviceContext';
import CheckInternetConnection from '../utils/InternetUtils';
import axios from 'axios';
import config from '../config/config';

function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const { showLoading, hideLoading } = useLoading();
  const { serialNumber, formattedDate, getFormattedDate, timestamp, updateResponseData } = useDeviceContext();

  const handleBackClick = () => {
    navigate(-1);
  };

  const refreshHandleClick = useCallback(async () => {
    showLoading();
    if (!navigator.geolocation) {
      showAlert('error', 'Error', 'La geolocalización no es soportada por tu navegador.');
      return;
    }

    const online = await CheckInternetConnection();
    if (!online) {
      showAlert('error', 'Error de conexión.', 'Compruebe que su dispositivo esté conectado a Internet.');
    } else {
      setTimeout(() => {
        
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;
            let isFirstCall = false;
            try {
              const params = new URLSearchParams({
                serialNumber,
                latitude,
                longitude,
                timestamp,
                isFirstCall
              }).toString();

              const response = await axios.get(`${config.apiGateway.URL}Devices/Verify?${params}`);

              switch (response.status) {
                case 200:
                  getFormattedDate(formattedDate);
                  updateResponseData(response.data);
                  showAlert('success', 'Éxito', 'Datos refrescados correctamente.');
                  break;
                default:
                  showAlert('error', 'Error', 'Error en la verificación del dispositivo. Inténtalo de nuevo.');
                  break;
              }
            } catch (error) {
              if (error.response) {
                const { status } = error.response;
                switch (status) {
                  case 410:
                    showAlert('error', 'Número de Serie Incorrecto', error.response.data);
                    break;
                  case 411:
                    showAlert('error', 'Fuera de rango', "El dispositivo móvil está fuera de rango con el vehículo, Asegurese que el dispositivo está en un radio de 20m en función de su localización.");
                    break;
                  case 412:
                    showAlert('error', 'Tiempo de Transmisión mayor de 5 minutos.', "Asegúrese que el dispositivo está transmitiendo, puede acelerar la transmisión encendiendo y apagando el motor.");
                    break;
                  case 413:
                    showAlert('error', 'Sin GPS en el vehículo.', "El dispositivo no dispone de señal GPS, asegúrese que está en un entorno abierto donde pueda obtener señal.");
                    break;
                  default:
                    showAlert('error', 'Error', 'Error en la verificación del dispositivo. Inténtalo de nuevo.');
                    break;
                }
              } else {
                showAlert('error', 'Error', 'Hubo un error en la verificación del dispositivo. Inténtalo de nuevo.');
              }
            } finally {
              hideLoading();
            }
          },
          (error) => {
            showAlert('error', 'Sin GPS en el móvil.', 'Debe tener activado el GPS del móvil.');
            hideLoading();
          }
        );
      }, 1000);
    }


  }, [serialNumber, timestamp, formattedDate, getFormattedDate, updateResponseData, showAlert, showLoading, hideLoading]);

  useEffect(() => {
    // Verificar que serialNumber y timestamp estén definidos antes de configurar el intervalo
    if (location.pathname === '/device-verification' && serialNumber && timestamp) {
      // eslint-disable-next-line
      const intervalId = setInterval(refreshHandleClick, 60000); // 15000 ms = 15 segundos

      // Limpieza del intervalo cuando el componente se desmonte o cambie la ruta
      return () => clearInterval(intervalId);
    }
  }, [location.pathname, serialNumber, timestamp, refreshHandleClick]);

  return (
    <AppBar sx={{ boxShadow: 'unset' }} position={location.pathname === "/" ? "static" : "sticky"} color="primary">
      <Toolbar>
        {location.pathname !== '/' && (
          <IconButton edge="start" color="inherit" aria-label="back" onClick={handleBackClick}>
            <ArrowBackIcon />
          </IconButton>
        )}
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {location.pathname === '/device-check' && 'Comprobar dispositivo'}
          {location.pathname === '/device-verification' && 'Verificando dispositivo'}
          {location.pathname === '/connection-error' && 'Verificando dispositivo'}
        </Typography>
        {location.pathname === '/device-verification' && (
          <RefreshIcon onClick={refreshHandleClick} style={{ fontSize: 25 }} />
        )}
        {location.pathname !== '/' && location.pathname !== "/device-verification" && (
          <img style={{ width: 15 }} src={Logo4G} alt="Logo" sx={{ ml: 'auto' }} />
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
