import isOnline from 'is-online';

const CheckInternetConnection = async () => {
  try {
    const online = await isOnline();
    return online;
  } catch (error) {
    console.error('Error al verificar conexión:', error);
    return false; // Manejo de error: asumimos que no está conectado
  }
};

export default CheckInternetConnection;