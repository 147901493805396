import React, { createContext, useState, useEffect, useContext } from 'react';

const DeviceContext = createContext();

export const useDeviceContext = () => useContext(DeviceContext);

export const DeviceProvider = ({ children }) => {
  const [serialNumber, setSerialNumber] = useState('');
  const [locationState, setLocationState] = useState(null);
  const [responseData, setResponseData] = useState(JSON.parse(localStorage.getItem('responseData')));
  const [loading, setLoading] = useState(false);
  const [timestamp, setTimestamp] = useState(null);
  const [formattedDate, setFormattedDate] = useState('');

  useEffect(() => {
    const savedResponseData = localStorage.getItem('responseData');
    if (savedResponseData) {
      setResponseData(JSON.parse(savedResponseData));
    }

    // Obtener la fecha formateada inicial al inicio
    setFormattedDate(getFormattedDate());
  }, []);

  const updateSerialNumber = (newSerialNumber) => {
    setSerialNumber(newSerialNumber);
    localStorage.setItem('serialNumber', newSerialNumber); // Guardar en localStorage

    // Actualizar la fecha formateada solo si cambia el serialNumber
    if (serialNumber !== newSerialNumber) {
      setFormattedDate(getFormattedDate());
    }
  };

  const updateLocationState = (state) => {
    setLocationState(state);
  };

  const updateResponseData = (data) => {
    setResponseData(data);
    localStorage.setItem('responseData', JSON.stringify(data)); // Guardar en localStorage
  };

  const updateTimestamp = (timestamp) => {
    setTimestamp(timestamp);
    localStorage.setItem('timestamp', timestamp); // Guardar en localStorage
  }

  const getFormattedDate = () => {
    const currentDate = new Date();
    const meses = [
      'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio',
      'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
    ];
    const dia = String(currentDate.getDate()).padStart(2, '0');
    const mes = meses[currentDate.getMonth()];
    const año = currentDate.getFullYear();
    const hora = String(currentDate.getHours()).padStart(2, '0');
    const minutos = String(currentDate.getMinutes()).padStart(2, '0');
    const segundos = String(currentDate.getSeconds()).padStart(2, '0');
    setFormattedDate(`${dia} ${mes} ${año} ${hora}:${minutos}:${segundos}`);
    return `${dia} ${mes} ${año} ${hora}:${minutos}:${segundos}`;
  };

  return (
    <DeviceContext.Provider
      value={{
        serialNumber,
        updateSerialNumber,
        locationState,
        updateLocationState,
        responseData,
        updateResponseData,
        getFormattedDate: getFormattedDate,
        formattedDate,
        loading,
        setLoading,
        timestamp,
        updateTimestamp
      }}
    >
      {children}
    </DeviceContext.Provider>
  );
};
