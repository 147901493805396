import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#a4003c', // Color rojo
    },
    secondary: {
      main: '#ffffff', // Color blanco
    },
  },
  typography: {
    
    h6: {
      fontSize: 18,
    },
  },
  shape: {
    borderRadius: 8, // Ajustar según sea necesario
  },
});

export default theme;