// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import theme from './theme/theme';
import DeviceVerification from './pages/DeviceVerification';
import ConnectionError from './pages/ConnectionError';
import DeviceCheck from './pages/DeviceCheck';
import Navbar from './components/Navbar';
import { DeviceProvider } from './context/DeviceContext';
import { LoadingProvider } from './context/LoadingContext';
import { AlertProvider } from './context/AlertContext'; // Importa AlertProvider
import LoadingScreen from './components/LoadingScreen';

function App() {
  useEffect(() => {
    const lockOrientation = () => {
      if (window.screen.orientation && window.screen.orientation.lock) {
        window.screen.orientation.lock('portrait').catch(err => {
          console.error('Orientation lock failed:', err);
        });
      }
    };

    lockOrientation();

    window.addEventListener('resize', lockOrientation);

    return () => {
      window.removeEventListener('resize', lockOrientation);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <DeviceProvider>
          <LoadingProvider>
            <AlertProvider>
              <Navbar />
              <Routes>
                <Route path="/device-verification" element={<DeviceVerification />} />
                <Route path="/connection-error" element={<ConnectionError />} />
                <Route path="/device-check" element={<DeviceCheck />} />
                <Route path="/" element={<DeviceCheck />} />
              </Routes>
              <LoadingScreen />
            </AlertProvider>
          </LoadingProvider>
        </DeviceProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
