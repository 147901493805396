import React from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CustomAppBar from '../components/CustomAppBar';
import { useDeviceContext } from '../context/DeviceContext';
import { useNavigate } from 'react-router-dom';
import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded';
import { Box, List, ListItem, ListItemText, ListItemIcon, Typography } from '@mui/material';
import { useAlert } from '../context/AlertContext';
import Alert from '../components/Alert';


function DeviceVerification() {
  const { responseData, serialNumber, formattedDate } = useDeviceContext();
  //const [alert, setAlert] = useState({ open: false, severity: '', title: '', message: '' });
  const { alert } = useAlert();

  const navigate = useNavigate();

  const convertTimestampToDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const dia = String(date.getUTCDate()).padStart(2, '0');
    const mes = String(date.getUTCMonth() + 1).padStart(2, '0');
    const año = date.getUTCFullYear();
    const horas = String(date.getUTCHours()).padStart(2, '0');
    const minutos = String(date.getUTCMinutes()).padStart(2, '0');
    const segundos = String(date.getUTCSeconds()).padStart(2, '0');
    return `${dia}/${mes}/${año} ${horas}:${minutos}:${segundos}`;
  };

  if (responseData === null) {
    navigate(-1);
  }

  const adjustSensorValue = (name, value) => {
    if (name && name.toLowerCase().includes('puerta')) {
      return value ? 'abierta' : 'cerrada';
    } else {
      return value ? 'Activado' : 'Desactivado';
    }
  };

  const sensor1 = {
    title: responseData.diN2_Name ? `${responseData.diN2_Name}` : null,
    value: adjustSensorValue(responseData.diN2_Name, responseData.diN2),
    status: responseData.diN2_Activations > 0 ? 'Correcto' : 'No Disponible'
  };

  const sensor2 = {
    title: responseData.diN3_Name ? `${responseData.diN3_Name}` : null,
    value: adjustSensorValue(responseData.diN3_Name, responseData.diN3),
    status: responseData.diN3_Activations > 0 ? 'Correcto' : 'No Disponible'
  };

  const sensor3 = {
    title: responseData.diN4_Name ? `${responseData.diN4_Name}` : null,
    value: adjustSensorValue(responseData.diN4_Name, responseData.diN4),
    status: responseData.diN4_Activations > 0 ? 'Correcto' : 'No Disponible'
  };

  const isTempValid = (temp) => {
    temp = parseFloat(temp);
    if (isNaN(temp)) {
      return { isValid: false, status: 'Error', message: 'Valor no válido' };
    }
    if (temp === 0.0) {
      return { isValid: false, status: 'No disponible', message: 'No disponible' };
    } else if (temp === 300.0) {
      return { isValid: false, status: 'No disponible', message: 'No detectado' };
    } else if (temp > 150) {
      return { isValid: false, status: 'error', message: 'Error: temperatura demasiado alta' };
    } else if (temp <= 50 && temp > -20) {
      return { isValid: true, status: 'Correcto', message: 'Correcto' };
    } else {
      return { isValid: false, status: 'error', message: 'Error: temperatura fuera de rango' };
    }
  };
  // Obtener el estado de la temperatura 1
  const temp1Status = isTempValid(responseData.temp1);
  const temperature1 = {
    title: responseData.temp1_Name,
    value: responseData.temp1 === "0.0" ? "--" : responseData.temp1 === "300.0" ? "No detectado" : responseData.temp1 + "º",
    status: temp1Status.status,
    message: temp1Status.message
  };

  const temp2Status = isTempValid(responseData.temp2);
  const temperature2 = {
    title: responseData.temp2_Name,
    value: responseData.temp2 === "0.0" ? "--" : responseData.temp2 === "300" ? "No detectado" : responseData.temp2 + "º",
    status: temp2Status.status,
    message: temp2Status.message
  };

  const data = [
    {
      title: 'Geolocalización',
      coordinates: responseData.latitude + ", " + responseData.longitude,
      date: convertTimestampToDate(responseData.datetime),
      status: 'Correcto'
    },
    temperature1,
    temperature2,
    sensor1,
    sensor2,
    sensor3
  ];

  // Filtrar los elementos sin nombre
  const filteredData = data.filter(item => item.title !== null && item.title !== "");

  const handleClickError = (itemType) => {

    let errorMessage, errorDescription, textList1, textList2, textList3, textList4, textList5, textButton, errorType;

    if (itemType === 'Geolocalización') {
      errorMessage = 'Geolocalización';
      errorDescription = 'Asegúrese que el dispositivo GPS está instalado según las indicaciones técnicas.';
      textList1 = 'El vehículo no está bajo techo';
      textList2 = 'Los cables están conectados a la batería del vehículo';
      textButton = 'Verificar dispositivo GPS';
    } else if (itemType === 'Temperatura 1' || itemType === 'Temperatura 2') {
      errorMessage = 'Temperatura';
      errorDescription = 'Asegúrese que el sensor está instalado según las indicaciones técnicas.';
      textList1 = 'El vehículo no está bajo techo';
      textList2 = 'Compruebe que el sensor de temperatura corresponde con el emparejamiento al dispositivo GPS';
      textButton = 'Verificar';
      errorType = "temperatura";
    } else {
      errorMessage = itemType;
      errorDescription = 'Asegúrese que el sensor está instalado según las indicaciones técnicas.';
      textList1 = itemType.toLowerCase().includes("puerta") ? 'Abra la puerta' : 'Active el sensor';
      textList2 = 'Compruebe que el sensor está funcionando correctamente';
      textList3 = itemType.toLowerCase().includes("puerta") ? 'Cierre la puerta' :'Desactive el sensor';
      textList4 = 'Espere al menos 2 minutos';
      textList5 = 'Pulse el botón verificar';
      textButton = 'Verificar ' + itemType;
      errorType = "sensor";
    }
    navigate('/connection-error', {
      state: {
        serialNumber,
        errorMessage,
        errorDescription,
        textList1,
        textList2,
        textList3,
        textList4,
        textList5,
        textButton,
        date: formattedDate,
        errorType: errorType
      }
    });
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <CustomAppBar
        title={responseData.serialNumber}
        subtitle={formattedDate}
      />
      <List style={{ padding: 20, paddingTop: 10 }}>
        {filteredData.map((item, index) => (
          <ListItem key={index} divider>
            <ListItemText
              primary={
                <Typography variant="h5" sx={{ color: 'primary.main' }}>
                  {item.title}
                </Typography>
              }
              secondary={
                <>
                  <Typography component="span" fontWeight="bold" display="block">
                    {item.coordinates || item.value}
                  </Typography>
                  <Typography component="span" display="block">
                    {item.date}
                  </Typography>
                </>
              }
            />
            <ListItemIcon >
              {item.status === 'Correcto' ? (
                <Box style={{ marginRight: 40 }} display="flex" flexDirection="column" alignItems="center">
                  <CheckCircleOutlineIcon fontSize="large" color="success" />
                  <Typography variant="body2" color="success">Correcto</Typography>

                </Box>

              ) : item.status === 'error' ? (
                <Box onClick={() => handleClickError(item.title)} display="flex" alignItems="center">
                  <Box style={{ marginRight: 16 }} display="flex" flexDirection="column" alignItems="center">
                    <DangerousRoundedIcon fontSize="large" color="error" />
                    <Typography variant="body2" color="error">Error</Typography>
                  </Box>
                  <Box ml={1}>
                    <KeyboardArrowRightIcon />
                  </Box>
                </Box>
              ) : (
                <Box onClick={() => handleClickError(item.title)} display="flex" alignItems="center">
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <WarningAmberRoundedIcon fontSize="large" sx={{ color: 'orange' }} />
                    <Typography variant="body2" sx={{ color: 'orange' }}>No disponible</Typography>
                  </Box>
                  <Box >
                    <KeyboardArrowRightIcon />
                  </Box>
                </Box>
              )}
            </ListItemIcon>
          </ListItem>

        ))}
      </List>

      {alert.open && (
        <Alert
          severity={alert.severity}
          title={alert.title}
          message={alert.message}
        />
      )}
    </Box>
  );
}

export default DeviceVerification;
