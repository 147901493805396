import React, { useState } from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import CustomAppBar from '../components/CustomAppBar';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { styled } from '@mui/system';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDeviceContext } from '../context/DeviceContext';
import axios from 'axios';
import config from '../config/config';
import Alert from '../components/Alert';
import { useLoading } from '../context/LoadingContext';

function ConnectionError() {
  const location = useLocation();
  const navigate = useNavigate();
  const { errorMessage, errorDescription, textList1, textList2, textList3, textList4, textList5, textButton, serialNumber, errorType } = location.state || {};
  const { getFormattedDate, updateResponseData, timestamp, formattedDate } = useDeviceContext();
  const [alert, setAlert] = useState({ open: false, severity: '', title: '', message: '' });
  const { showLoading, hideLoading } = useLoading();

  const date = formattedDate;

  const RoundedButton = styled(Button)(({ theme }) => ({
    margin: 'auto',
    borderRadius: '50%', // Hace que el botón sea completamente redondo
    width: '75px',
    height: '75px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1), // Ajusta el espacio interno del botón según sea necesario
    marginTop: 50
  }));

  const handleBackClick = () => {
    if (errorType !== "") {
      showLoading();
      setTimeout(() => {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;
            let isFirstCall = false;
            try {
              const params = new URLSearchParams({
                serialNumber,
                latitude,
                longitude,
                timestamp,
                isFirstCall
              }).toString();

              const response = await axios.get(`${config.apiGateway.URL}Devices/Verify?${params}`);

              switch (response.status) {
                case 200:
                  getFormattedDate(formattedDate)
                  updateResponseData(response.data);
                  navigate(-1);
                  break;
                default:
                  setAlert({
                    open: true,
                    severity: 'error',
                    title: 'Error',
                    message: 'Error en la verificación del dispositivo. Inténtalo de nuevo.'
                  });
                  break;
              }
            } catch (error) {
              if (error.response) {
                const { status } = error.response;
                switch (status) {
                  case 410:

                    setAlert({
                      open: true,
                      severity: 'error',
                      title: 'Número de Serie Incorrecto',
                      message: error.response.data
                    });
                    break;
                  case 411:

                    setAlert({
                      open: true,
                      severity: 'error',
                      title: 'Fuera de rango',
                      message: "El dispositivo móvil está fuera de rango con el vehículo, Asegurese que el dispositivo está en un radio de 20m en función de su localización."
                    });
                    break;
                  case 412:
                    setAlert({
                      open: true,
                      severity: 'error',
                      title: 'Tiempo de Transmisión mayor de 5 minutos.',
                      message: "Asegúrese que el dispositivo está transmitiendo, puede acelerar la transmisión encendiendo y apagando el motor."
                    });
                    break;
                  case 413:
                    setAlert({
                      open: true,
                      severity: 'error',
                      title: 'Sin GPS en el vehículo.',
                      message: "El dispositivo no dispone de señal GPS, asegúrese que está en un entorno abierto donde pueda obtener señal."
                    });

                    break;
                  default:
                    setAlert({
                      open: true,
                      severity: 'error',
                      title: 'Error',
                      message: 'Error en la verificación del dispositivo. Inténtalo de nuevo.'
                    });
                    break;
                }
              } else {
                setAlert({
                  open: true,
                  severity: 'error',
                  title: 'Error',
                  message: 'Hubo un error en la verificación del dispositivo. Inténtalo de nuevo.'
                });
              }
            } finally {
              hideLoading(); // Ocultar loading
            }
          },
          (error) => {
            hideLoading();
            setAlert({
              open: true,
              severity: 'error',
              title: 'Sin GPS en el móvil.',
              message: 'Debe tener activado el GPS del móvil.'
            });
          }
        );
      }, 1000);

    } else {
      navigate(-1);
    }

  };

  return (
    <Box>
      <CustomAppBar
        title={serialNumber}
        subtitle={date}
      />
      <Container sx={{ padding: 5, paddingTop: 3 }} maxWidth="xs">
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2, ml: -3 }}>
          <WarningAmberRoundedIcon sx={{ fontSize: 45, color: (theme) => errorType.includes("sensor") || errorType.includes("temperatura") ? 'orange' : theme.palette.primary.main, ml: 1 }} />
          <Typography variant="h5" sx={{ color: (theme) => errorType.includes("sensor") || errorType.includes("temperatura") ? 'orange' : theme.palette.primary.main, ml: 1 }}>
            {errorMessage}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', maxWidth: 320, justifyContent: 'center', margin: 'auto', paddingTop: 3 }}>
          <Typography variant="body1" gutterBottom color="textSecondary" >
            {errorDescription}
          </Typography>
        </Box>


        <Box sx={{ margin: 'auto', maxWidth: 300, paddingTop: 3 }}>
          {
            textList1 &&
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              <Box sx={{ minWidth: '25px', flex: '0 0 auto', marginRight: '10px', color: 'primary.main', fontWeight: 'bold', fontSize: 20 }}>1</Box>
              <Typography variant="body1" color="textSecondary">
                {textList1}
              </Typography>
            </Box>
          }
          {
            textList2 &&
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              <Box sx={{ minWidth: '25px', flex: '0 0 auto', marginRight: '10px', color: 'primary.main', fontWeight: 'bold', fontSize: 20 }}>2</Box>
              <Typography variant="body1" color="textSecondary">
                {textList2}
              </Typography>
            </Box>
          }

          {
            textList3 &&
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              <Box sx={{ minWidth: '25px', flex: '0 0 auto', marginRight: '10px', color: 'primary.main', fontWeight: 'bold', fontSize: 20 }}>3</Box>
              <Typography variant="body1" color="textSecondary">
                {textList3}
              </Typography>
            </Box>
          }
          {
            textList4 &&
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              <Box sx={{ minWidth: '25px', flex: '0 0 auto', marginRight: '10px', color: 'primary.main', fontWeight: 'bold', fontSize: 20 }}>4</Box>
              <Typography variant="body1" color="textSecondary">
                {textList4}
              </Typography>
            </Box>
          }
          {
            textList5 &&
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              <Box sx={{ minWidth: '25px', flex: '0 0 auto', marginRight: '10px', color: 'primary.main', fontWeight: 'bold', fontSize: 20 }}>5</Box>
              <Typography variant="body1" color="textSecondary">
                {textList5}
              </Typography>
            </Box>
          }


        </Box>
        <RoundedButton
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleBackClick}
        >
          <CheckCircleOutlineIcon style={{ fontSize: 65 }} />

        </RoundedButton>
        <Typography onClick={handleBackClick} style={{ color: '#5f5f5f', marginTop: 24, fontSize: 15, }} mb={5} variant="body2" component="div" align="center">
          {textButton}
        </Typography>
        <Box sx={{ mt: 5, width: 180, margin: 'auto' }}>
          <Typography variant="body2" color="textSecondary" sx={{ textAlign: 'center' }}>
            Si el error persiste, contacte con la asistencia técnica
          </Typography>
          <Typography sx={{ fontWeight: 'bold', textAlign: 'center' }} variant="body2" color="textSecondary">
            900 924 004
          </Typography>
        </Box>
        <Alert
          open={alert.open}
          severity={alert.severity}
          title={alert.title}
          message={alert.message}
          onClose={() => setAlert({ ...alert, open: false })}
        />
      </Container>
    </Box>
  );
}

export default ConnectionError;
