// src/components/Alert.js
import React from 'react';
import { Alert as MuiAlert, AlertTitle, Snackbar } from '@mui/material';
import { useAlert } from '../context/AlertContext';

const Alert = () => {
  const { alert, hideAlert } = useAlert();

  return (
    // <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
    //   <MuiAlert onClose={onClose} severity={severity} sx={{ width: '100%' }}>
    //     <AlertTitle>{title}</AlertTitle>
    //     {message}
    //   </MuiAlert>
    // </Snackbar>
    <Snackbar open={alert.open} autoHideDuration={6000} onClose={hideAlert}>
      <MuiAlert onClose={hideAlert} severity={alert.severity} sx={{ width: '100%' }}>
      <AlertTitle>{alert.title}</AlertTitle>
      {alert.message}
      </MuiAlert>
    </Snackbar>
  );
};

export default Alert;
