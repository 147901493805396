import React from 'react';
import { AppBar, Toolbar, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';

const StyledAppBar = styled(AppBar)({
  backgroundColor: (theme) => theme.palette.primary.main,
  height: '100px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  boxShadow: 'none',
});

function CustomAppBar({ title, subtitle }) {
  return (
    <StyledAppBar position="relative">
      <Toolbar>
        <Box sx={{ textAlign: 'center', mt: -3 }}>
          <Typography variant="h3" align="center" color="secondary">
            {title}
          </Typography>
          <Typography gutterBottom variant="subtitle1" align="center" color="secondary">
            {subtitle}
          </Typography>
        </Box>
      </Toolbar>
    </StyledAppBar>
  );
}

export default CustomAppBar;
